import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-parent.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "a-what-is-the-school-s-philosophy-about-student-discipline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#a-what-is-the-school-s-philosophy-about-student-discipline",
        "aria-label": "a what is the school s philosophy about student discipline permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`A. What Is The School’ S Philosophy About Student Discipline?`}</h2>
    <p><strong parentName="p">{`Overview`}</strong></p>
    <p>{`PSI is a place where we all belong. In order to create this atmosphere, we must have a school culture that promotes caring and acceptance. Our aim is to have a school in which everyone is treated with respect and are given the opportunity to learn in an environment that is engaging, nurturing and safe. This includes students, parents and staff.`}</p>
    <p>{`The PSI Core Values are at the heart of the PSI Behaviour Code of Conduct, because our goal is to develop students who exhibit integrity, honesty, trust, respect, empathy, tolerance, diversity, equity, inclusion, happiness, joy, and passion. We also look to the IB Learner Profile, which contains many of the same sentiments and guidelines.`}</p>
    <p>{`Action is another essential element of PSI’s culture. Students at PSI learn to take action to extend their learning and to make the world a better place. Students should understand that behaviour is an action, a choice they make, and an outcome of learning and understanding. Students should be responsible for every action and result of every action that they make.`}</p>
    <p>{`Parents can be involved by using the Core Values at home with their children. By recognising when their children are showing great behaviour or guiding them to make responsible choices, we can work together to promote self-awareness, self-efficacy and responsibility.`}</p>
    <p><strong parentName="p">{`Promoting Positive Behaviour`}</strong></p>
    <p>{`The best way to seek positive student behaviour is to actively encourage it. We do this by:`}</p>
    <p>{`1`}{`.`}{` Modelling appropriate behaviour`}</p>
    <p>{`2`}{`.`}{` Using praise and verbal recognition`}</p>
    <p>{`3`}{`.`}{` Involving students in decisions that affect them`}</p>
    <p>{`4`}{`.`}{` Having clear and consistent expectations`}</p>
    <p>{`5`}{`.`}{` Celebrating positive behaviour`}</p>
    <p><strong parentName="p">{`Clear and Consistent Expectations`}</strong></p>
    <p>{`If students know what is expected of them, they are better able to work and play together. The rules and guidelines have been developed by students, teachers and teaching support staff.`}</p>
    <p><strong parentName="p">{`Cultural Differences`}</strong></p>
    <p>{`We understand that there are sometimes cultural differences that influence what parents and students value and how they define ‘good behaviour’ and appropriate choices. Our goal is to remind students that they must accept PSI’s cultural norms and follow its guidelines when they are on campus or representing the school.`}</p>
    <h2 {...{
      "id": "b-how-are-students-encouraged-to-make-good-choices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#b-how-are-students-encouraged-to-make-good-choices",
        "aria-label": "b how are students encouraged to make good choices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`B. How Are Students Encouraged To Make Good Choices?`}</h2>
    <p><strong parentName="p">{`Student Problem Solving`}</strong></p>
    <p>{`At the beginning of the school year, teachers and counsellors teach and review problem-solving strategies. These are reinforced throughout the year in homerooms, other classes, assemblies and ECAs / sports. As a result, students develop the learner profile attributes, internalise the Core Values, and put them both into action.`}</p>
    <h2 {...{
      "id": "c-what-are-the-standards-for-behaviour",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#c-what-are-the-standards-for-behaviour",
        "aria-label": "c what are the standards for behaviour permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`C. What Are The Standards For Behaviour?`}</h2>
    <p>{`Behaviour is judged by the extent to which the attitudes and actions of the students contribute to or restrict the effective learning in the classroom, as well as the well-being and safety of community members. The intent is to provide a cooperative, supportive and safe environment where each individual feels respected, valued, and secure. To achieve this, the following standards have been established to encourage good relationships between pupils, teachers, and parents:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Classroom practices that encourage inclusion and respect`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A system that promotes and celebrates good behaviour`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A focus on positive reinforcement rather than negative consequences.`}</p>
      </li>
    </ul>
    <p>{`To this end, there is in place a clear system of celebration of success. This acknowledgement system includes the following:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Assemblies that focus on student learning and achievement`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Annual awards for both Primary and Secondary`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Academic and attitude awards at Secondary each semester`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Facebook postings and `}<em parentName="p">{`PSI Life`}</em>{` magazine articles about individual and group achievements`}</p>
      </li>
    </ul>
    <p>{`It should be noted that in case of a disagreement between staff and students regarding the suitability of consequences, school leadership makes the final decision.`}</p>
    <p><strong parentName="p">{`School-wide Classroom Expectations`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Respect others`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Engage in positive behaviours`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Be polite and helpful to all members of the community (teachers, support staff, students of all ages, parents and guests of PSI)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Use appropriate language at all times`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Speak English as the language of the community, except in classes designed to teach or support other languages`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ask a teacher or other staff member for help if you do not understand what you have been asked to do`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Report instances of any unacceptable behaviour Tell your teacher or another staff member if you or another student is upset or being bullied`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Be prepared for school and use your resources appropriately Have your laptop with you and use it every day`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{` Bullying `}</strong></p>
    <p>{`Ukrainian laws regarding bullying guide the school’s definition and response to bullying. By law, bullying is defined as follows:`}</p>
    <p>{`Any act, including the use of electronic communications, that is committed against a student and results in psychological, physical, economic or sexual harm. By its nature, bullying is a series of repeated actions, not a single event. It involves an imbalance of power, which means that there is a perceived difference in the physical development, social status, communications skills, health or mental development between the bully and the victim.`}</p>
    <p>{`It does not include disagreements (even long-standing arguments) between perceived equals, or one-off situations.`}</p>
    <p>{`By Ukrainian law, any situation that meets this definition demands that the school administration contact local law enforcement officials. This may lead to further action from the school, including suspension or expulsion, and / or further consequences from the local police.`}</p>
    <p>{`As a result, the school works hard to educate all students and adults about what bullying is, how to avoid it, ways to support each other, and the seriousness of the consequences.`}</p>
    <p>{`When the School has evidence of inappropriate online communications between students, the school leadership notifies parents and provides that evidence. We ask parents to follow up on such off-campus behaviours. When those communications indicate a pattern of bullying, school leadership must act under Ukrainian law as indicated above.`}</p>
    <p><strong parentName="p">{`Online Behaviour`}</strong></p>
    <p>{`In distance learning, PSI uses Zoom or similar platforms to connect and work with students. Teachers share `}<a parentName="p" {...{
        "href": "https://docs.google.com/document/d/1yvmGdV981LPZTVZAG0uWCmscwR7dMKJy_svx3D9uHIA/edit"
      }}>{` a set of schoolwide expectations `}</a>{` with students regarding their behaviour and participation in those settings.`}</p>
    <h2 {...{
      "id": "d-what-steps-does-the-school-take-when-a-student-misbehaves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#d-what-steps-does-the-school-take-when-a-student-misbehaves",
        "aria-label": "d what steps does the school take when a student misbehaves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`D. 'What Steps Does The School Take When A Student Misbehaves?'`}</h2>
    <p><strong parentName="p">{`Overview`}</strong></p>
    <p>{`Teachers handle most discipline problems. If the offense is serious enough, or in the case of repeated defiance of a teacher's or staff member's authority, the school leadership intervenes. Teachers track and document all cases of student misconduct serious enough to disrupt other students or the educational program.`}</p>
    <p>{`There are clear, consistent and fairly administered consequences. A typical sequence includes the following:`}</p>
    <p>{`1`}{`.`}{` warning`}</p>
    <p>{`2`}{`.`}{` consequence (loss of certain privileges, note home, etc.)`}</p>
    <p>{`3`}{`.`}{` detention`}</p>
    <p>{`4`}{`.`}{` internal suspension / parent conference`}</p>
    <p>{`6`}{`.`}{` external suspension / parent conference`}</p>
    <p>{`7`}{`.`}{` expulsion`}</p>
    <p>{`The school leadership reserves the right to move immediately past the warning stage as appropriate. Under certain circumstances, school leadership may choose to suspend or move to expel without going through other steps.`}</p>
    <p><strong parentName="p">{`Suspension`}</strong></p>
    <p>{`Students are suspended for serious offenses. Suspensions can vary in length and may take place internally (e.g., in the principal’s office) or externally (at home). During the period of suspension, the student cannot attend any school events during the time of that suspension, but s/he may work with teachers to keep current in course work. External suspensions are viewed as more serious and can be noted on student applications to other schools or universities upon a request from those schools.`}</p>
    <p>{`It’s important to note that we always begin with a consequence that links to the offense and discussions between the child(ren) involved, the principal or deputy principal, and / or the counsellor. Parents are always informed about any disciplinary action. Once all of these steps have occurred, and if the child continues to demonstrate the negative behaviour, we then move to the possibility of suspension.`}</p>
    <p>{`Examples of behaviours that result in suspension in Primary School may include the following:`}</p>
    <table><colgroup><col style={{
          "width": "50%"
        }} /><col style={{
          "width": "50%"
        }} /></colgroup><thead><tr className="header"><th> <p>Offense</p> </th><th> <p>Primary School</p> </th></tr></thead><tbody><tr className="odd"><td>Repeated violations of the Code of Conduct</td><td> <p>Meeting with the principal and parents, possible meetings with the school counsellor; continued violation of code of conduct can lead to 1 – 3 day in-school suspension.</p> </td></tr><tr className="even"><td>Physical aggression toward another person</td><td> <p>1 - 3 day in school suspension; meeting with principal and parents; meeting with the school counsellor. Repeated aggression leads to out-of-school suspension and possible expulsion</p> </td></tr><tr className="odd"><td>Repeated or flagrant defiance of teacher or staff authority</td><td> <p>Meeting with the principal and parents, possible meetings with the school counsellor, 1 – 3 days in-school suspension; counselling. Repeated aggression leads to out-of-school suspension and possible expulsion.</p> </td></tr><tr className="even"><td>Misuse of technology such as cyber-bullying, repeatedly going to prohibited sites</td><td>Suspension of the use of technology, a project assigned in light of the offense; repeated misuse can lead to in-school suspension.</td></tr><tr className="odd"><td> <p>Serious and / or repeated acts of cheating (Upper Primary)</p> </td><td>Discussion with teacher, 1:1 lessons on research, task assigned, meeting with principal and parents.</td></tr></tbody></table>
    <p>{`Examples of behaviours that result in suspension in Middle and High School include the following:`}</p>
    <table><colgroup><col style={{
          "width": "33%"
        }} /><col style={{
          "width": "33%"
        }} /><col style={{
          "width": "33%"
        }} /></colgroup><thead><tr className="header"><th> <p>Offense</p> </th><th> <p>Middle School (Grades 6 - 8)</p> </th><th> <p>High School (Grades 9 - 12)</p> </th></tr></thead><tbody><tr className="odd"><td>Repeated violations of the Code of Conduct</td><td> <p>1 - 3 day suspension</p> </td><td> <p>3 - 5 day suspension</p> </td></tr><tr className="even"><td>Possession or use of tobacco, alcohol or drugs on campus or at a school sponsored event</td><td> <p>1 - 3 days suspension; counselling upon return</p> </td><td> <p>3 - 5 days suspension; counselling upon return</p> </td></tr><tr className="odd"><td>Distribution or sale of alcohol, tobacco or drugs on campus or at a school sponsored event</td><td>Suspension pending a review by the Board Expulsion Committee</td><td>Suspension pending a review by the Board Expulsion Committee</td></tr><tr className="even"><td> <p>Repeated or flagrant defiance of teacher or staff authority</p> </td><td>1 - 3 days in school suspension</td><td>3 - 5 days in school suspension</td></tr><tr className="odd"><td> <p>Serious and / or repeated acts of plagiarism or cheating</p> </td><td><p>A zero on the assignment (starting with second offense)</p><p>A zero on the test (starting with the first offense)</p><p>Possible suspension for repeated offences</p></td><td><p>A zero on the assignment (starting with second offense)</p><p>A zero on the test or exam (starting with first offense)</p><p>Possible suspension for repeated offences</p></td></tr><tr className="even"><td> <p>Theft or criminal activities</p> </td><td> <p>1 - 3 days suspension; possible contact of local authorities</p> </td><td> <p>3 - 5 days suspension; possible contact of local authorities</p> </td></tr><tr className="odd"><td> <p>Possession of a dangerous weapon</p> </td><td>Suspension pending a review by the Board Expulsion Committee</td><td>Suspension pending a review by the Board Expulsion Committee</td></tr></tbody></table>
    <p>{`Please note that the School reserves the right to alter the consequences listed above based on the severity of the incident and the pattern of behaviour for individual students. It is important to note that two external suspensions within one calendar year may result in a referral to the school’s Review Committee and / or Expulsion Committee.`}</p>
    <p><strong parentName="p">{`Review Committee`}</strong></p>
    <p>{`PSI has a Review Committee made up of teachers and administrators. The committee meets to discuss individual students who may meet one or more of the following terms:`}</p>
    <p>{`-Externally suspended twice in a calendar year and / or`}</p>
    <p>{`-A pattern of detentions and internal suspensions that have not resolved behavioural issues`}</p>
    <p>{`The committee reviews the student’s complete record - attendance and punctuality, academic achievement, attitude and application grades, teacher anecdotal information, and discipline record. Parents and the student may also choose to speak to the committee.`}</p>
    <p>{`The committee will make one or more of the following recommendations:`}</p>
    <p>{`-An academic / behavioural contract that outlines specific terms for the student, their parents, and the school. NOTE: Students who do not adhere to the terms of the contract may also be recommended for expulsion or non-renewal.`}</p>
    <p>{`-A recommendation for expulsion or non-renewal. These cases go to the expulsion committee (see below).`}</p>
    <p>{`The committee will reconvene at the end of each semester to determine whether the contract should be concluded, extended or revised.`}</p>
    <p>{`Students who are recommended for expulsion or non-renewal fall under the guidelines below.`}</p>
    <p><strong parentName="p">{`Expulsion`}</strong></p>
    <p>{`Expulsion of students occurs for any acts that seriously threaten the safety of students and staff and / or for repeated acts of misconduct despite other disciplinary actions.`}</p>
    <p>{`The School convenes an Expulsion Committee made up of the homeroom teacher or appropriate IB coordinator, the director and at least one Board member. The student may also request a specific teacher to serve as an advocate; this teacher is a non-voting member of the committee. Based on evidence presented and input from the parents and other teachers, the Committee decides whether or not to expel. The decision to expel must be unanimous. Students who are expelled are not allowed to return to PSI at any time, either to re-enrol or to visit the campus. Students who are not expelled are placed on a behaviour contract that is reviewed quarterly for at least one calendar year.`}</p>
    <p>{`The committee may also opt not to renew a student’s enrolment at the end of the school year.`}</p>
    <p>{`Examples of behaviour that may result in expulsion:`}</p>
    <p>{`1`}{`.`}{` Sale or distribution of drugs, tobacco or alcohol on campus`}</p>
    <p>{`2`}{`.`}{` Violence or repeated incidents of fighting`}</p>
    <p>{`3`}{`.`}{` Gross violation of the Code of Conduct or repeated defiance of school rules and authority`}</p>
    <p>{`5`}{`.`}{` Possession of a weapon`}</p>
    <p>{`6`}{`.`}{` A referral from the Review Committee`}</p>
    <p><strong parentName="p">{`Please note that issues specific to each division are contained in the Primary (page 70) or Secondary (page 78) sections of this handbook.`}</strong></p>
    <p><strong parentName="p">{`GUIDELINES FOR PRIMARY STUDENTS AND PARENTS`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      